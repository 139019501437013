import React from 'react'
import { MdLocationOn, MdCall, MdEmail } from "react-icons/md";

const Contact = () => {
  return (
    <div id='contact' className='bg-[#021C23] text-white py-8'>
      <div className="container mx-auto p-4">
        <h2 className='text-center'><b>Let's Make Something Amazing Together</b></h2>
        <div className="flex flex-wrap justify-between my-10">
          <div className='w-full lg:w-1/4'>
            <h3 className='text-[#8144E5]'><b><u>StreetsmartAds</u></b></h3>
            <p>Is an advertising agency based in Nairobi,Kenya focused to turn around the business of customers, giving them more engagements and increase in sales.</p>
          </div>
          <div className='w-full lg:w-1/4'>
            <h3 className='text-[#8144E5]'><b><u>Quick Links</u></b></h3>
            <ul className='px-4 list-disc'>
              <li className='hover:underline'><a href="#home">Home</a></li>
              <li className='hover:underline'><a href="#about">About</a></li>
              <li className='hover:underline'><a href="#services">Services</a></li>
            </ul>
          </div>
          <div className='w-full lg:w-1/4'>
            <h3 className='text-[#8144E5]'><b><u>Contact Us</u></b></h3>
            <h6 className='flex items-center'><span className='text-[#8144E5]'><MdLocationOn/></span>&nbsp;Nairobi, Kenya</h6>
            <h6 className='flex items-center'><span className='text-[#8144E5]'><MdCall/></span>&nbsp;0700000000</h6>
            <h6 className='flex items-center'><span className='text-[#8144E5]'><MdEmail/></span>&nbsp;info@streetsmart.com</h6>
          </div>
          <div className='w-full lg:w-1/4'>
            <h3 className='text-[#8144E5]'><b><u>Learn More</u></b></h3>
            <ul className='px-4 list-disc'>
              <li className='hover:underline'><a href="#home">Faqs</a></li>
              <li className='hover:underline'><a href="#about">How it works</a></li>
              <li className='hover:underline'><a href="https://www.accounts.streetsmartad.com/terms&condition" target='_blank' rel='noreferrer'>Terms&Conditions</a></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Contact
