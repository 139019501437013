import React from 'react'
import partner1 from './assests/credofaster.png'
import partner2 from './assests/onfonmedia.png'
import partner3 from './assests/onfonmobile.png'
import partner4 from './assests/street.jpeg'

const Home = () => {
  return (
    <div id='home' className='bg-[#f2ecfc]'>
      <div className="container mx-auto xl:p-4">
        <section className="flex flex-wrap items-center justify-center p-4">
          <div className='w-full lg:w-1/2 p-4 space-y-8'>
            <h1 className='text-5xl font-bold'>Unlock your business potential with <span className='text-[#8144E5]'>smart street ads</span> advertising</h1>
            <p className='font-bold'>Smart Street Ads has a network of more than 100K people who could be your potential customers</p>
            <div>
              <span className='bg-[#8144E5] hover:bg-[#8d55e7] text-white px-8 py-4 rounded-full'><a href="https://www.accounts.streetsmartad.com" target='_blank' rel='noreferrer'>Work with us</a></span>
            </div>
          </div>
          <div className='w-full lg:w-1/2 p-4'>
            <img
              width={800}
              height={800}
              src="https://static.vecteezy.com/system/resources/previews/007/819/465/original/advertising-or-ads-illustration-for-mobile-social-media-campaign-business-promotion-brand-and-digital-marketing-in-flat-cartoon-style-vector.jpg"
              alt=""
              className='mix-blend-multiply'
            />
          </div>
        </section>

        <section className='p-4'>
          <h2 className='text-[#8144E5] text-center'><b>Our proud partners</b></h2>
          <div className="grid grid-cols-2 gap-4 md:grid-cols-2 lg:grid-cols-5 my-6">
            <img src={partner2} width={200} height={200} className='h-full object-contain' alt="partner1" />
            <img src={partner1} width={200} height={200} className='h-full object-contain' alt="partner2" />
            <img src={partner3} width={200} height={200} className='h-full object-contain' alt="partner4" />
            <img src={partner4} width={200} height={200} className='h-full object-contain' alt="partner5" />
            <img src={partner2} width={200} height={200} className='h-full object-contain' alt="partner6" />
          </div>
        </section>
      </div>
    </div>
  )
}

export default Home
