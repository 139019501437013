import Nav from './pages/Nav/Nav'
import Home from './pages/Home'
import About from './pages/About'
import Services from './pages/Services'
import Contact from './pages/Contact'
import Footer from './pages/Footer'
import ScrollToTop from './pages/ScrollToTop'


function App() {
  return (
    <div>
      <Nav/>
      <Home/>
      <About/>
      <Services/>
      <Contact/>
      <Footer/>
      <ScrollToTop/>
    </div>
  );
}

export default App;
