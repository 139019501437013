import React, {useState} from 'react'
import { FaBars } from "react-icons/fa"
import './Nav.css'
import logo from '../assests/StreetsmartAds.png'

const Nav = () => {

  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);

  return (
    <main className="nav backdrop-blur-lg">
      <div>
        <div className="container mx-auto p-4">
          <nav className='flex flex-wrap items-center justify-between px-4 py-1'>
            {/* Top Nav */}
            <span>
              <img width={250} height={200} src={logo} alt="" />
            </span>
            <ul className={click ? "nav-menu active" : "nav-menu"}>
              <li>
                <a href="#home" className="hover:text-black px-3 py-2 ">Home</a>
              </li>
              <li>
                <a href="#about" className="hover:text-black px-3 py-2 ">About</a>
              </li>
              <li>
                <a href="#services" className="hover:text-black px-3 py-2 ">Services</a>
              </li>
              <li>
                <a href="#contact" className="hover:text-black px-3 py-2 ">Contact</a>
              </li>
            </ul>
            <div className={"nav-menu"}>
              <span className='bg-[#8144E5] text-white px-5 py-2 rounded-full hover:bg-[#8d55e7]'><a href="https://www.accounts.streetsmartad.com" target='_blank' rel='noreferrer'>Get Started</a></span>
            </div>
            <button className="nav-icon" onClick={handleClick}>
              <span><FaBars/></span>
            </button>
          </nav>
        </div>
      </div> 
    </main>
  )
}

export default Nav