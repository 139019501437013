import React from 'react'

const Footer = () => {
  return (
    <div className='bg-[#021C23]'>
      <hr />
      <div className="container mx-auto p-4">
        <div className='text-center text-white'>
          <p>&copy; 2024 Code Wave Technologies</p>
        </div>
      </div>
    </div>
  )
}

export default Footer
